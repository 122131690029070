<div class="picker-container"
     [ngClass]="isSelected ? colorScheme.selected : colorScheme.unselected"
     (click)="onClick()">
  <lib-lazy-image
    *ngIf="item.logo"
    [picture]="item.logo"
    [targetRes]="imageRes"
    class="logo-image">
  </lib-lazy-image>
  <!-- Always use Abbreviations on mobile -->
  <div class="d-block d-md-none">
    <span>{{item.abbreviation ? item.abbreviation : item.name}}</span>
  </div>
  <!-- Always use Abbreviations on mobile, except for states -->
  <div class="d-none d-md-block">
    <span *ngIf="item.type === PickerItemType.State">{{item.name}}</span>
    <span *ngIf="item.type !== PickerItemType.State">{{item.abbreviation ? item.abbreviation : item.name}}</span>
  </div>
</div>
