import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {environment} from '../../../../../../../src/environments/environment';
import {LAZY_SCRIPT_NAME, LazyScriptLoaderService} from '@utils/services/lazy-script-loader/lazy-script-loader.service';
import {EnvName} from '@utils/models/environment/env-name';
import {BehaviorSubject} from 'rxjs';
import {IConsent} from '@utils/models/consent/consent';
import {SharedConsentService} from '@utils/services/shared-consent/shared-consent.service';
import {ConsentAction} from '@utils/models/consent/consent-action';
import {ConsentCategory} from '@utils/models/consent/consent-category';
import {CookieService} from '@utils/services/cookie/cookie.service';
import {CookieName} from '@utils/services/cookie/cookie-name';
import {Cookies} from '@utils/services/cookie/cookies';
import {isPlatformBrowser} from '@angular/common';
import { WebviewService } from '../webview/webview.service';
import { MatSnackBar } from '@angular/material/snack-bar';

declare const CookieScript: any;

@Injectable({
  providedIn: 'root'
})
export class ConsentService {

  public cookieScriptInstance: any;
  public consentChange = new BehaviorSubject<IConsent>(SharedConsentService.consent);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private lazyScriptLoader: LazyScriptLoaderService,
    private snackbar: MatSnackBar,
  ) {}

  public async init(appVersion: string, resetConsent: boolean, appConsent: boolean): Promise<void> {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    const lastVersion = CookieService.getCookie(CookieName.VERSION);
    const needsReset = lastVersion !== appVersion && resetConsent;
    CookieService.setCookie(Cookies[CookieName.VERSION], appVersion);

    window.addEventListener('CookieScriptLoaded', () => {
      console.log('[CMP] Needs reset because version change: ', needsReset);
      if (needsReset) {
        setTimeout(() => {
          const consentDialog = document.getElementById('cookiescript_injected');
          let alreadyOpen = false;
          if (consentDialog) {
            alreadyOpen = consentDialog.style.display !== 'none'
          }
          if (!alreadyOpen) {
            this.cookieScriptInstance.show();
          }
        }, 500);
      }
    });

    console.log('[CMP] Initialize');
    if (environment.name === EnvName.Prod) {
      await this.lazyScriptLoader.load(LAZY_SCRIPT_NAME.COOKIE_SCRIPT_PROD);
    }
    if (environment.name === EnvName.Test) {
      await this.lazyScriptLoader.load(LAZY_SCRIPT_NAME.COOKIE_SCRIPT_TEST);
    }
    if (environment.name !== EnvName.Dev) {
      this.cookieScriptInstance = CookieScript.instance;
    }

    this.snackbar.open(`App consent: ${appConsent}`, '', {duration: 3000});
    if (appConsent !== undefined) {
      appConsent ? this.cookieScriptInstance?.acceptAllAction() : this.cookieScriptInstance?.rejectAllAction();
    }
    if (appConsent === undefined && WebviewService.isWebview) {
      this.cookieScriptInstance?.acceptAllAction();
    } 

    let consentState: IConsent = null;
    // Fake consent on localhost
    if (environment.name === EnvName.Dev) {
      consentState = {
        action: ConsentAction.Accept,
        categories: Object.values(ConsentCategory) as ConsentCategory[],
        CMP: null,
      }
    } else {
      consentState = this.cookieScriptInstance.currentState();
      consentState.CMP = this.cookieScriptInstance.getCMPCookie();
    }

    if (!consentState) {
      console.warn('[CMP] Could not fetch current consent state!');
      return;
    }
    SharedConsentService.consent = consentState;
    if (consentState.action === ConsentAction.Accept && environment.name !== EnvName.Dev) {
      this.handleAcceptConsent();
      console.log('[CMP] Consent already accepted: ', SharedConsentService.consent);
    }
    this.consentChange.next(consentState);
    window.addEventListener('CookieScriptAcceptAll', this.handleAcceptConsent.bind(this));
    window.addEventListener('CookieScriptAccept', this.handleAcceptConsent.bind(this));
  }

  public hasAccepted(): boolean {
    return SharedConsentService.consent?.action === ConsentAction.Accept;
  }

  public hasAcceptedCategory(category: ConsentCategory): boolean {
    if (!SharedConsentService.consent || SharedConsentService.consent.action !== ConsentAction.Accept) {
      return false;
    }
    return SharedConsentService.consent.categories.includes(category);
  }

  private handleAcceptConsent(): void {
    SharedConsentService.consent = this.cookieScriptInstance.currentState();
    SharedConsentService.consent.CMP = this.cookieScriptInstance.getCMPCookie();
    this.consentChange.next(SharedConsentService.consent);
    console.log('[CMP] Accepted consent: ', SharedConsentService.consent);
  }

}
