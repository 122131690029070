import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Location, isPlatformBrowser } from "@angular/common";
import { BreakpointObserver } from "@angular/cdk/layout";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";
import { EnvName } from "@utils/models/environment/env-name";
import { LayoutService } from "../../../../projects/shared/utils/src/lib/services/layout/layout.service";
import { UrlService } from "@utils/services/url/url.service";
import { WebviewPlatform, WebviewService } from "@utils/services/webview/webview.service";

export const Breakpoints = {
  SmallSet: "(max-width: 767px)",
};

@Injectable({
  providedIn: "root",
})
export class StateService {
  public static isBrowser = false;
  public static isWebview = false; // Deprecated: Use flag from WebviewService
  // It is important that this flag is undefined if it is not set by query param
  public static appConsent: boolean | undefined = undefined;
  public static platform: WebviewPlatform;

  // TODO: WARNING: Always unsubscribe from these subjects - might cause mem leaks
  public static breakpoints = new BehaviorSubject<{ [key: string]: boolean }>(
    {}
  );

  readonly USER_IS_LOGGED_IN = "APPSTATE_USER_LOGGED_IN";
  readonly OPEN_SIGNIN_WINDOW = "OPEN_SIGNIN_WINDOW";

  private state: { [id: string]: any } = {
    [this.USER_IS_LOGGED_IN]: undefined,
    [this.OPEN_SIGNIN_WINDOW]: undefined,
  };
  private registry: { [id: string]: any[] } = {};
  private clickHandlerActive = false;

  public static get isProd(): boolean {
    return environment.name === EnvName.Prod || UrlService.UseProdUrls;
  }

  public static isMobile(): boolean {
    return StateService.breakpoints.value[Breakpoints.SmallSet];
  }

  constructor(
    @Inject(PLATFORM_ID) protected platformId: Object,
    private location: Location,
    private breakpointObserver: BreakpointObserver,
    private layoutService: LayoutService,
    private webviewService: WebviewService
  ) {
    this.init();
  }

  private init(): void {
    StateService.isWebview = this.webviewService.isWebview();
    WebviewService.isWebview = StateService.isWebview;
    this.processQueryParams();
    StateService.isBrowser = isPlatformBrowser(this.platformId);
    if (StateService.isBrowser && window.location.href.includes('localhost:4200')) {
      UrlService.UseLocalWebUrls = true;
    }
    // TODO: Remove StateService flag
    if (StateService.isWebview) {
      this.layoutService.hideLayout();
    }
    this.observeBreakpoint();
  }

  private processQueryParams(): void {
    const path = this.location.path();
    const parts = path?.split('?')
    if (!parts || parts.length < 2) {
      return;
    }
    const params = new URLSearchParams(parts[1]);
    const redirected = params.get("redirected");
    const hideNav = params.get("hide-nav");
    const chromeless = params.get("chromeless");
    const appConsent = params.get("consent");
    const appVersion = params.get("app-version");
    const prodToggle = params.get("prod");
    const platform = params.get("platform");
    if (appConsent) {
      StateService.appConsent = appConsent === "true";
    }

    if (prodToggle === "true") {
      UrlService.UseProdUrls = true;
      if (StateService.isBrowser) {
        this.registerLinkClickHandler();
      }
    }
    if (hideNav === "true") {
      console.log(`%c[STATE] Hide nav`, "color: dodgerblue; font-size: 16px");
      this.layoutService.hideHeaderAndNav();
    }
    if (chromeless === "true") {
      console.log(`%c[STATE] Hide nav`, "color: dodgerblue; font-size: 16px");
      this.layoutService.hideLayout();
    }
    if (redirected) {
      console.log(`%c[STATE] Redirected`, "color: dodgerblue; font-size: 16px");
      this.layoutService.whiteLabelHeader = true;
    }
    if (appVersion?.length > 0) {
      const parts = appVersion.split(".");
      WebviewService.platform = (Number(parts[0]) < 9) ? WebviewPlatform.Android : WebviewPlatform.iOS;
    }
    if (platform) {
      WebviewService.platform = ("app-" + platform) as WebviewPlatform;
    }
  }

  private registerLinkClickHandler(): void {
    if (this.clickHandlerActive) {
      return;
    }
    this.clickHandlerActive = true;
    // Appends prod=true if UseProdUrls is active, only used on test website
    window.addEventListener("click", (event: PointerEvent) => {
      const closestLink = event
        .composedPath()
        .find(
          (target: HTMLElement) => target.tagName === "A"
        ) as HTMLAnchorElement;
      const href = closestLink?.href;
      const routerLink = closestLink?.getAttribute("ng-reflect-router-link");
      if (!href?.length || routerLink) {
        return;
      }
      // Check if fan.at link
      let currentHost = UrlService.LocalUrls.webHost;
      if (environment.name === EnvName.Test) {
        currentHost = UrlService.DevUrls.webHost;
      }
      if (environment.name === EnvName.Prod) {
        currentHost = UrlService.ProdUrls.webHost;
      }
      if (!href.includes(currentHost)) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      window.location.href = href + "?prod=true";
    });
  }

  private get(id: string) {
    return this.state[id];
  }

  set(id: string, value: any, force = false) {
    if (!force && this.state[id] === value) {
      return;
    }
    this.state[id] = value;
    this.callSubscribers(id);
  }

  toggle(id: string) {
    this.set(id, !this.get(id));
  }

  private getSubscribers(id: string) {
    return this.registry[id];
  }

  private callSubscribers(id: string) {
    const subscribers = this.getSubscribers(id);
    if (!subscribers) {
      return;
    }

    const value = this.get(id);
    const len = subscribers.length;
    for (let i = 0; i < len; i++) {
      if (subscribers[i]) {
        subscribers[i](value);
      }
    }
  }

  cleanupSubscribers(id: string) {
    const subs = this.getSubscribers(id);
    let cleanupIndex = 0;

    const l = subs.length;
    for (let i = 0; i < l; i++) {
      if (subs[i]) {
        cleanupIndex = i;
      }
    }
    subs.length = cleanupIndex + 1;
  }

  subscribe(id: string, callback: any) {
    let subscribers = this.getSubscribers(id);

    if (subscribers === undefined) {
      subscribers = [];
      this.registry[id] = subscribers;
    }

    this.cleanupSubscribers(id);

    const sub = subscribers.find((subscriber) => subscriber === callback);

    if (sub === undefined) {
      subscribers.push(callback);
    } else {
      console.log("Already subscribed");
    }

    callback(this.get(id));

    // return this.get(id);
    return this.buildUnsubscriptionObject(id, subscribers.length - 1);
  }

  unsubscribe(id: string, callback: any) {
    const subscribers = this.getSubscribers(id);

    if (subscribers === undefined) {
    } else {
      const sub = subscribers.find((subscriber) => subscriber === callback);
      if (sub) {
        const index = subscribers.indexOf(sub);
        if (index > -1) {
          subscribers.splice(index, 1);
        }
      } else {
        console.log("SUB NOT FOUND");
      }
    }
  }

  buildUnsubscriptionObject(id: string, index: number) {
    const subscribers = this.getSubscribers(id);
    if (subscribers) {
      return {
        unsubscribe: function () {
          delete subscribers[index];
        },
      };
    } else {
      return {
        unsubscribe: function () {
          console.log("UNSUBSCRIPTION ERROR");
        },
      };
    }
  }

  private observeBreakpoint(): void {
    const breakPoints = Object.values(Breakpoints);
    this.breakpointObserver.observe(breakPoints).subscribe((result) => {
      StateService.breakpoints.next(result.breakpoints);
    });
  }
}
