import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from "@angular/core";
import { AdService } from "../../services/ad.service";
import { Subscription } from "rxjs";
import { environment } from "../../../../../../../src/environments/environment";
import { isPlatformBrowser } from "@angular/common";
import { AdName } from "@lib-modules/ads/models/ad/ad";
import { AdUnit } from "@lib-modules/ads/models/ad-unit";
import { WebviewService } from "@utils/services/webview/webview.service";

@Component({
  selector: "lib-ad",
  templateUrl: "./ad.component.html",
  styleUrls: ["./ad.component.scss"],
})
export class AdComponent implements OnInit, OnDestroy {
  public unit: AdUnit;
  @Input() public name: AdName;
  @Output() public ready = new EventEmitter();
  @Input() private breakpoints: string[];
  @HostBinding("class.empty") public isEmpty = false;
  public height = "600px";
  public width = "300px";
  public isTestAd = false;
  public displayed = false;
  private shouldDisplay = false;
  private breakpoint$: Subscription;
  private slotRendered$: Subscription;
  private adsServiceInit$: Subscription;
  private collapseAds$: Subscription;
  private initialized = false;
  private isProd = environment.name === "production";
  private bannerContainer: HTMLElement;

  @ViewChild("adBannerContainer") set adBannerContainer(
    adBannerContainer: ElementRef
  ) {
    if (adBannerContainer?.nativeElement) {
      this.bannerContainer = adBannerContainer.nativeElement;
      this.init();
    }
  }

  constructor(
    @Inject(PLATFORM_ID) protected platformId: any,
    private adService: AdService,
  ) {}

  public ngOnInit(): void {
    if (!this.name) {
      this.name = AdName.Content;
    }
    if (WebviewService.isWebview) {
      this.height = "0";
      this.width = "0";
    } 
  }

  public ngOnDestroy() {
    this.breakpoint$?.unsubscribe();
    this.slotRendered$?.unsubscribe();
    this.adsServiceInit$?.unsubscribe();
    this.collapseAds$?.unsubscribe();
    console.log("[Ad] Destroy container", this.unit?.id);
  }

  public init(): void {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    if (!this.canDisplay() || !isPlatformBrowser(this.platformId)) {
      return;
    }
    // If a breakpoint is set in this array, only show the ad if it matches
    if (this.breakpoints?.length > 0) {
      this.breakpoint$ = this.adService.breakpoints.subscribe((breakpoints) => {
        this.shouldDisplay = this.breakpoints.some((bp) => breakpoints[bp]);
        this.shouldDisplay ? this.display() : this.destroy();
      });
      return;
    }
    this.shouldDisplay = true;
    this.display();
  }

  public canShow(): boolean {
    return this.isTestAd ? this.displayed : this.adService.consent;
  }

  private display(): void {
    if (!this.initialized) {
      return;
    }
    if (this.breakpoints) {
      this.shouldDisplay = this.breakpoints.some(
        (bp) => this.adService.breakpoints.getValue()[bp]
      );
    }
    if (this.displayed || !this.shouldDisplay) {
      return;
    }
    this.isEmpty = false;
    this.displayed = true;
    if (this.isTestAd) {
      return;
    }
    this.unit = this.adService.createUnit(this.name);
    if (!this.unit.hasPlaceholder) {
      this.height = "0";
      this.width = "0";
    }
    this.bannerContainer.setAttribute("id", this.unit.id);
    console.log("[Ad] Created ", this.unit.id);
    this.adService.load(this.unit);
  }

  private destroy(): void {
    this.isEmpty = true;
    this.displayed = false;
    this.unit = null;
    this.bannerContainer.removeAttribute("id");
  }

  private canDisplay(): boolean {
    if (
      this.isProd ||
      this.adService.displayRealAdsSetting ||
      !isPlatformBrowser(this.platformId)
    ) {
      return true;
    }
    if (this.adService.displayTestAdsSetting) {
      this.setTestAd();
      return true;
    }
    this.isEmpty = true;
    return false;
  }

  private setTestAd(): void {
    this.isTestAd = true;
    this.updateTestAdSize();
    this.isEmpty = false;
  }

  private updateTestAdSize(): void {
    if (!this.isTestAd) {
      return;
    }
    this.height = "0";
    this.width = "0";
    if (this.name.includes("content")) {
      this.height = "250px";
      this.width = "300px";
    }
    if (this.name.includes("beside")) {
      this.height = "600px";
      this.width = "300px";
    }
  }
}
