import { AdName } from "./ad/ad";
import { AdConfig } from "./ad/ads-config";

export class AdUnit {
  id: string;
  count: number;
  name: AdName;
  hasPlaceholder = true;
  
  constructor(name: AdName, unitCount: number) {
    this.name = name;
    this.count = unitCount;
    const unitPath = AdConfig.get(name);
    this.id = unitPath;
    if (name !== AdName.Infinity || unitCount > 0) {
      this.id += `_${unitCount}`;
    }
    // Ads with placeholder have a fixed height until the ad loads (300x600px)
    // Everything in viewport should have a fixed height to improve CLS value
    if (name === AdName.Content && unitCount > 1) {
      this.hasPlaceholder = false;
    }
    if (name === AdName.Infinity) {
      this.hasPlaceholder = false;
    }
  }
}

