import {
  Compiler,
  EventEmitter,
  Injectable,
  Injector,
  ViewContainerRef,
} from "@angular/core";
import { LibLazyComponents } from "@components/lib-lazy-components";
import { LibLazyComponentsService } from "@components/lib-lazy-components.service";
import {
  BitmovinPlayerComponent,
  IPlayerSettings,
} from "@components/video-player/bitmovin-player/bitmovin-player.component";
import { ApiErrors } from "@api/http/api-errors";
import { SubscriptionsHttpService } from "@api/http/subscriptions/subscriptions-http.service";
import { IPromotionBanner } from "@api/models/promotions/promotion-banner-response";
import { PromotionsHttpService } from "@api/http/promotions/promotions-http.service";
import { UserJourney, UserJourneyPhase } from "@api/models/promotions/funnel";
import { OverlayService } from "@lib-modules/overlays/overlay.service";
import { PromoOverlayComponent } from "@lib-modules/overlays/components/promo-overlay/promo-overlay.component";
import { LocalStorageKey } from "@utils/storage/storage-keys";

export interface IVideoPaywallSettings {
  activeTeamIds?: string[];
  activeEventId?: string;
  activeNewsId?: string;
  activeClubId?: string;
}

@Injectable({
  providedIn: "root",
})
export class LibVideoPlayerService {

  public logout = new EventEmitter();
  public needsUpdate = true;
  public isInitialized = false;
  public isSubscribed = false;
  public isVerified = true;
  public paywallSettings: IVideoPaywallSettings;
  public updatePromise: Promise<any>;
  public aboUserFunnel: {
    promoBanner: IPromotionBanner,
    successBanner: IPromotionBanner,
  };
  private components: any;

  constructor(
    private compiler: Compiler,
    private injector: Injector,
    private libLazyComponentsService: LibLazyComponentsService,
    private subscriptionHttp: SubscriptionsHttpService,
    private promosHttp: PromotionsHttpService,
    private overlayService: OverlayService,
  ) {}

  public resetSubscriptionInfo(): void {
    this.needsUpdate = true;
    this.isSubscribed = false;
    this.isVerified = true;
    this.isInitialized = false;
  }

  public async updateSubscriptionInfo(userId: string): Promise<void> {
    console.log('Update sub info');
    try {
      if (this.updatePromise) {
        return this.updatePromise;
      }
      this.isVerified = true;
      this.updatePromise = this.subscriptionHttp.getSubscriptionStatus(userId);
      const data = await this.updatePromise;
      if (!this.aboUserFunnel) {
        await this.getAboFunnel();
      }
      this.isSubscribed = data.subscribed;
      this.updatePromise = null;
      this.needsUpdate = false;
      this.isInitialized = true;
      const paymentActive = localStorage.getItem(LocalStorageKey.PaymentActive) === 'true';
      if (paymentActive && this.isSubscribed) {
        this.overlayService.open(PromoOverlayComponent, this.aboUserFunnel.successBanner);
      }
      localStorage.removeItem(LocalStorageKey.PaymentActive);
    } catch (e) {
      this.updatePromise = null;
      if (e.status === ApiErrors.EmailNotVerified.Status) {
        this.isVerified = false;
      } else {
        throw e;
      }
    }
  }

  /**
   * This lazy loads the bitmovin player because this thing is massive
   */
  public async loadPlayer(
    container: ViewContainerRef,
    settings: IPlayerSettings
  ): Promise<BitmovinPlayerComponent> {
    if (!container) {
      console.error("[BM Player] Could not load player - container was empty!");
      return;
    }
    if (!this.components) {
      this.components =
        await this.libLazyComponentsService.loadBitmovinPlayerModule();
    }
    const { LibVideoPlayerModule } = await import(
      "@components/video-player/lib-video-player.module"
    );
    // Compile the module
    const moduleFactory = await this.compiler.compileModuleAsync(
      LibVideoPlayerModule
    );
    // Create a moduleRef, resolve an entry component, create the component
    const moduleRef = moduleFactory.create(this.injector);
    const component = this.components[LibLazyComponents.bitmovinPlayer];
    const componentFactory =
      moduleRef.componentFactoryResolver.resolveComponentFactory<BitmovinPlayerComponent>(
        component
      );
    const { instance } = container.createComponent<BitmovinPlayerComponent>(
      componentFactory,
      null,
      moduleRef.injector
    );
    instance.settings = settings;
    return instance;
  }

  public async getAboFunnel(): Promise<void> {
    if (this.aboUserFunnel) {
      return;
    }
    this.aboUserFunnel = { promoBanner: null, successBanner: null };
    this.aboUserFunnel.promoBanner = await this.promosHttp.getFunnelByJourneyAndPhase(
      UserJourney.Subscribe, UserJourneyPhase.Promo
    );
    this.aboUserFunnel.successBanner = await this.promosHttp.getFunnelByJourneyAndPhase(
      UserJourney.Subscribe, UserJourneyPhase.Success
    );
    console.log('Funnel: ', this.aboUserFunnel)
  }
}
