import {AdName} from './ad';

export const MAX_CONTENT_ADS = 3;
const AD_BASE = '/6771346/sfa_fan.at/web.fan.at';

export const AdBreakpoints = {
  BesideRight: '(min-width: 1350px)',
  BesideLeft: '(min-width: 1650px)',
  TickerColumnRight: '(min-width: 768px)',
  HomePageColumnLeft: '(max-width: 1399px)',
}

export const AdConfig = new Map<string, string>([
  [ AdName.BesideLeft, AD_BASE + '/ros_ry/beside_left' ],
  [ AdName.BesideRight, AD_BASE + '/ros_ry/beside_right' ],
  [ AdName.Content, AD_BASE + '/ros_ry/content' ],
  [ AdName.Infinity, AD_BASE + '/ros_ry/infinity' ],
])
